import React from "react";
import CaAdminLayout from "./CaAdminLayout";
import CaStudentTable from "./CaStudentTable";
import { RouteComponentProps } from "react-router";
import { Col } from "../../components/grid";
import PageSectionRow from "../../components/PageSectionRow";
import { StudentResumeListing } from "jobjump-types/CaStudents";
import { IconSearch } from "@tabler/icons";
import CaResume from "../../data/CaResume";

const ResumeListing : React.FC<RouteComponentProps> = (props) => {
    const [data, setData] = React.useState<StudentResumeListing[]>([]);
    React.useEffect(() => {
        CaResume.getAllResumes().then(setData)
    }, []);
    const gotoResume = (resume:StudentResumeListing) => {
        props.history.push(`/admin/resume/${resume.student.id}/${resume.resume_id}`);
        return;
    }
    return <CaAdminLayout active="careers" title="Student Resumes" {...props}>
        <PageSectionRow>
            <Col className="text-center">
                <h3>Student Resumes</h3>
                <span>Below are the resumes generated by your students. You can click on the <IconSearch /> to see the resume in detail.</span>
            </Col>
        </PageSectionRow>
        <PageSectionRow> 
            <Col>
                <CaStudentTable
                    data={data}
                    additionalColumns={[
                        { Header:"Career/Category Name", id:"resume_name", accessor:(r)=>r.resume_name, width:3 },
                        { Header:"View", width:1, Cell:({row:{original}})=><IconSearch className="pointer" onClick={()=>gotoResume(original)} /> },
                    ]} />
            </Col>
        </PageSectionRow>
    </CaAdminLayout>
}

export default ResumeListing;